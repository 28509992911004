.CardWrapper {
  display: flex;
  flex-wrap: wrap;
}

.Pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Card {
  //display: flex;
  border: 1px solid gray;
  //width: 300px;

  .image {
    img {
      max-width: 50px;
    }
  }

  .info {
    max-width: 50px;
    font-size: 10px;
    //margin-left: 5px;
    display: flex;
    flex-direction: column;
    //align-items: start;
    //justify-content: center;
    div {
      white-space: nowrap;
    }

    .buttonCopy {
      //height: 10px;
      margin-top: 2px;
      font-size: 8px;
      width: 100%;
    }
  }
}
